import { graphql } from 'gatsby';
import React from 'react';
import { CartTemplate } from '@matix/gatsby-theme-shopify';
const PageQuery = (props) => {
   return <CartTemplate {...props} />;
};

export const pageQuery = graphql`
   query cartTemplateThemeCore($locale: String, $uuid: String) {
      pageQuery: matixShopifyCartPage(frontmatter: { uuid: { eq: $uuid } }, locale: { eq: $locale }) {
         locale
         ...CartPageThemeShopify
      }
      pageQueryLocale: allMatixMdx(filter: { frontmatter: { uuid: { eq: $uuid } } }) {
         nodes {
            ...PageQueryLocaleThemeCore
         }
      }
   }
`;

export default PageQuery;
